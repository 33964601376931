import React from 'react';
import {Link} from 'gatsby'

const PageNotFound = () => {
    return (
        <>
            <h1>404: Page Not Found</h1>
            <Link to={`/`}>&laquo; Home</Link>
        </>
    );
};

export default PageNotFound;
